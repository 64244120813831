.wall {
  .wallList {
    border: 1px solid var(--neutralQuaternary);
    border-radius: 12px;
    overflow: hidden;
    overflow: auto;
    margin-left: 16px;
    @media (max-width: 1023px) {
      margin: 8px;
      margin-left: 0;
      width: 100%;
      height: 345px;
    }
    .header {
      position: sticky;
      top: 0;
      background-color: var(--white);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
    }
    .body {
      margin-bottom: 8px;
    }
    &::-webkit-scrollbar {
      background-color: transparent;
    }
  }
  @media (min-width: 1024px) {
    .wall-container {
      display: flex;
    }
    .wallList {
      aspect-ratio: 1;
    }
  }
  .description {
    background-color: var(--neutralLighter);
    padding: 8px 12px;
    border-radius: 12px;
  }
  .provider-icon {
    width: 40px;
  }
  .creator {
    color: var(--neutralPrimary) !important;
    &:hover {
      text-decoration: none;
    }
  }
  .votes {
    height: 100%;
    .ms-Button {
      min-width: 0;
      background-color: var(--neutralLighterAlt);
      border: none;
      height: 100%;
      &:first-child {
        border-top-left-radius: 999px;
        border-bottom-left-radius: 999px;
      }
      &:last-child {
        border-top-right-radius: 999px;
        border-bottom-right-radius: 999px;
      }
      &:hover {
        background-color: var(--neutralLighter);
      }
      &:active {
        background-color: var(--neutralLight);
      }
    }
  }
  .share {
    height: 100%;
    .ms-Button {
      min-width: 0;
      background-color: var(--neutralLighterAlt);
      border: none;
      height: 100%;
      border-radius: 999px;
      &:hover {
        background-color: var(--neutralLighter);
      }
      &:active {
        background-color: var(--neutralLight);
      }
    }
  }
}

.wall-detail {
  padding: 0;
}

@media (min-width: 1024px) {
  .wall-detail {
    padding-right: 12px;
  }
}

.wall-shimmer {
  .video .ms-Shimmer-shimmerWrapper {
    aspect-ratio: 1.77778;
    border-radius: 12px;
  }

  .heading {
    margin-top: 8px !important;
  }

  .image {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 32px;

    .ms-Shimmer-shimmerWrapper {
      height: 32px;
      border-radius: 100%;
    }
  }
}
