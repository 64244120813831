.container {
  width: 100%;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    max-width: 810px;
    padding-right: 32px;
    padding-left: 32px;
  }

  h1 {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1050px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1230px;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1920px;
  }
}
