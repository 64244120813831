@import '~office-ui-fabric-core/dist/css/fabric.min.css';
@import './app/styles/base.scss';
@import './app/styles/variables.scss';
@import './utils/theme/light.scss';
@import './utils/theme/dark.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  width: 11px;
  background-color: var(--white);
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background: var(--neutralTertiary);
  border: 2px solid var(--white);
  border-radius: 24px;
  &:active {
    background: var(--neutralSecondary);
  }
}
