.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-3 {
  margin-top: 12px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.mt-6 {
  margin-top: 24px !important;
}

.mb-0 {
  margin-bottom: 0;
}

.my-6 {
  margin: 24px 0;
}

.m-auto {
  margin: auto;
}

.ml-1 {
  margin-left: 4px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.px-2 {
  padding: 0 8px;
}

.pt-2 {
  padding-top: 8px;
}

.pr-1 {
  padding-right: 4px;
}

.mt-n-2 {
  margin-top: -8px;
}

.mt-n-6 {
  margin-top: -24px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}
