/*Keep theme.ts and this file in sync*/
/*Class name is one of the keys of ThemeModes enum in theme.ts*/
.dark {
  --fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif";
  --themePrimary: #229fff;
  --themeLighterAlt: #01060a;
  --themeLighter: #051929;
  --themeLight: #0a304d;
  --themeTertiary: #145f99;
  --themeSecondary: #1d8ce0;
  --themeDarkAlt: #37a8ff;
  --themeDark: #56b6ff;
  --themeDarker: #83c9ff;
  --neutralLighterAlt: #2b2b2b;
  --neutralLighter: #333333;
  --neutralLight: #414141;
  --neutralQuaternaryAlt: #4a4a4a;
  --neutralQuaternary: #515151;
  --neutralTertiaryAlt: #6f6f6f;
  --neutralTertiary: #c8c8c8;
  --neutralSecondary: #d0d0d0;
  --neutralPrimaryAlt: #dadada;
  --neutralPrimary: #ffffff;
  --neutralDark: #f4f4f4;
  --black: #f8f8f8;
  --white: #222222;
  --cardShadowHovered: #000;
}
