.link-list {
  border-radius: 8px;
  &.selected {
    background-color: var(--neutralLighter);
  }
  .image-container {
    height: 80px;
    aspect-ratio: 1.77778;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    cursor: pointer;
    & .ms-Shimmer-shimmerWrapper {
      aspect-ratio: 1.77778;
      border-radius: 8px;
    }
  }
  .heading,
  .creator {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .heading {
    -webkit-line-clamp: 2;
    height: 42px;
    color: var(--neutralPrimary) !important;
    &:hover {
      text-decoration: none;
    }
  }
  .creator {
    -webkit-line-clamp: 1;
    height: 20px;
  }
}
