@import '../../../styles/variables.scss';

.card {
  margin-bottom: 24px;
  border-radius: 8px;

  .image-container {
    width: 100%;
    aspect-ratio: 1.778;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    cursor: pointer;
    .ms-Shimmer-shimmerWrapper {
      border-radius: 8px;
      width: 100%;
      aspect-ratio: 1.778;
    }
  }

  .heading,
  .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 0 8px;
  }

  .heading {
    -webkit-line-clamp: 2;
    height: 42px;
    .ms-Shimmer-shimmerWrapper {
      margin-bottom: 4px;
      height: 22px;
    }
    color: var(--neutralPrimary);
  }

  .author {
    font-size: 12px;
    .ms-Icon {
      font-size: 10px;
      margin-left: 4px;
    }
  }

  .description {
    -webkit-line-clamp: 3;
    height: 62px;
    .ms-Shimmer-shimmerWrapper {
      margin-top: 4px;
    }
  }

  .ms-Button-menuIcon {
    display: none;
  }

  .footerButton {
    margin: 0;
    border: none;
  }

  .shimmer {
    margin-top: 4px;
  }

  &:hover {
    .heading {
      color: var(--themePrimary);
      &:hover {
        text-decoration: none;
      }
    }
  }

  .bookmarkIndicator {
    color: var(--themeLight);
    position: absolute;
    top: -8px;
    right: 12px;
    font-size: 18px;
  }
}

.shimmer-card {
  transition: transform 0.25s;
  animation: slideFromBottom;
  animation-duration: 0.25s;

  @keyframes slideFromBottom {
    0% {
      opacity: 0;
      transform: translateY(12px);
    }
    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
