.header-top {
  .search-container {
    max-width: 425px;
    margin-left: -68px !important;
  }

  @media (max-width: 768px-1) {
    .search-container {
      width: 100px;
      max-width: 325px;
    }
    .headerButtons {
      &.menu {
        margin-left: 0.5rem !important;
      }
      &.account {
        margin-right: 0.5rem !important;
      }
    }
  }

  @media (max-width: 992px-1) {
    .logo {
      .tubewall {
        display: none;
      }
    }
    .search-container {
      margin-left: 8px !important;
    }
  }

  .headerButtons {
    aspect-ratio: 1;
    min-width: 0;
    border-radius: 999px;
    margin-right: 8px;
    color: var(--black);
    &.primary {
      margin-left: 8px;
      color: var(--white);
    }
    &.menu {
      margin-left: 24px;
    }
    &.account {
      margin-right: 24px;
    }
  }

  .ms-Button-menuIcon {
    display: none;
  }
}

.header-panel {
  .ms-Panel-content {
    margin-top: 16px;
    * {
      color: var(--black) !important;
    }
  }
  .ms-Nav-groupContent {
    margin-bottom: 0;
  }
}

.header-sticky {
  position: sticky;
  top: 0;
  z-index: 999;
  .ms-Pivot-link {
    margin-top: 3px;
  }
}

.header-sticky-sep {
  position: sticky;
  top: 50px;
  z-index: 999;
  background: linear-gradient(180deg, var(--white) 51%, transparent 49%);
}
