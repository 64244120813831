@import './spacing.scss';
@import './container.scss';

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.mw-60 {
  width: 60%;
}

.mw-1024px {
  max-width: 1024px;
}

.t-left {
  text-align: left;
}

.ms-TextField-fieldGroup,
.ms-SearchBox {
  border-radius: 8px;
}

.hidden {
  display: none;
}

.ms-ContextualMenu-icon {
  color: var(--black) !important;
}

.clamp-2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.clamp-5 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 5;
}

.text-muted {
  color: var(--neutralTertiary) !important;
}

.ms-PanelAction-close {
  display: none;
}
